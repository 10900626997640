<template>
  <div>
    <div id="content" class="col-md-12" style="margin-top: 10px; padding: 0px;">
      <div class="products-category">
        <div class="category-desc">
          <div class="row">

            <div class="col-sm-12" v-if="!blockLoading">
              <div class="banners">
                <div>
                  <a href="#"
                    ><img
                      :src="flashbannerimage"
                      alt="img cate"
                      style="width: 1600px" /><br
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div style="text-align: -webkit-center">
          <div class="deals-layout1" v-if="showNowTimer && blocks.length != 0">
            <!-- <div class="head-title"> -->
            <!-- <div class="modtitle"> -->
            <div class="cslider-item-timer">
              <div class="product_time_maxprice">
                <div class="item-time">
                  <div class="item-timer">
                    <div class="defaultCountdown-30">
                      <div class="time-item time-day" style="margin-left: auto">
                        <div class="num-time" style="margin-bottom: 10px">
                          Flash Deal Ends in
                        </div>
                        <div class="name-time">Day</div>
                      </div>
                      <div class="time-item time-hour">
                        <div class="num-time">{{ dealHours.toFixed(0) }} HOUR</div>
                        <div class="name-time">Hour</div>
                      </div>
                      <div class="time-item time-min">
                        <div class="num-time">
                          {{ dealMinutes.split(".")[0] }} MIN
                        </div>
                        <div class="name-time">Min</div>
                      </div>
                      <div class="time-item time-sec">
                        <div class="num-time">{{ dealSeconds.toFixed(0) }} SEC</div>
                        <div class="name-time">Sec</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <!-- </div>     -->
            </div>
          </div>
        </div>

          <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center" style="text-align: -webkit-center;">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
          </div>

        <!-- <br/>
        <div class="row">
          <div class="col-md-12" style="text-align: center;">
             <h3 style="text-align: center;"><B>All Products</B></h3>            
          </div>
        </div> -->

        <!-- Filters -->
        <div class="product-filter product-filter-top filters-panel">
          <div class="row">
            <div class="col-md-5 col-sm-3 col-xs-12 view-mode">
              <div class="list-view">
                <!-- <button class="btn btn-default grid " @click.prevent.stop="display('grid')" :class="showmode == 'grid' ? 'active' : ''" data-view="grid" data-toggle="tooltip"  data-original-title="Grid"><i class="fa fa-th"></i></button> -->
                <!-- <button class="btn btn-default list" @click.prevent.stop="display('list')" :class="showmode == 'list' ? 'active' : ''" data-view="list" data-toggle="tooltip" data-original-title="List"><i class="fa fa-th-list"></i></button> -->
              </div>
            </div>
            <!-- <div class="short-by-show form-inline text-right col-md-7 col-sm-9 col-xs-12">
                    <div class="form-group short-by">
                        <label class="control-label" for="input-sort">Sort By:</label>
                        <select id="input-sort" class="form-control">
                            <option value="" selected="selected">Default</option>
                            <option value="">Name (A - Z)</option>
                            <option value="">Name (Z - A)</option>
                            <option value="">Price (Low &gt; High)</option>
                            <option value="">Price (High &gt; Low)</option>
                            <option value="">Rating (Highest)</option>
                            <option value="">Rating (Lowest)</option>
                            <option value="">Model (A - Z)</option>
                            <option value="">Model (Z - A)</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="input-limit">Show:</label>
                        <select id="input-limit" class="form-control" >
                            <option value="" selected="selected">15</option>
                            <option value="">25</option>
                            <option value="">50</option>
                            <option value="">75</option>
                            <option value="">100</option>
                        </select>
                    </div>
                </div> -->
          </div>
        </div>
        <!-- //end Filters -->

        <!--changed listings-->
        <div class="products-list row nopadding-xs so-filter-gird">
          <div class="col-md-12 text-center deals-layout1" v-if="showNextTimer">
            <!-- <div class="head-title"> -->
            <!-- <div class="modtitle"> -->
            <div class="cslider-item-timer" style="display: inline-block">
              <div class="product_time_maxprice">
                <div class="item-time">
                  <div class="item-timer">
                    <div class="defaultCountdown-30">
                      <div class="time-item time-day">
                        <div class="num-time">Next Flash Deals in</div>
                        <div class="name-time">Day</div>
                      </div>
                      <div class="time-item time-hour">
                        <div class="num-time">
                          {{ nextdealHours.toFixed(0) }}
                        </div>
                        <div class="name-time">Hour</div>
                      </div>
                      <div class="time-item time-min">
                        <div class="num-time">
                          {{ nextdealMinutes.split(".")[0] }}
                        </div>
                        <div class="name-time">Min</div>
                      </div>
                      <div class="time-item time-sec">
                        <div class="num-time">
                          {{ nextdealSeconds.toFixed(0) }}
                        </div>
                        <div class="name-time">Sec</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <!-- </div>     -->
            </div>
          </div>

          <div
            class="col-md-12 text-center deals-layout1"
            v-if="!showNextTimer && blocks.length === 0"
          >
            <!-- <h4>No Flash Deals Available !</h4> -->
          </div>

          <div
            class="product-layout productTileContainerFlash col-lg-2 col-md-4 col-sm-6 col-xs-12"
            v-for="(item, index) in blocks"
            :key="index"
          >
            <div
              class="product-item-container"
              style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)"
            >
              <div class="left-block left-b">
                <div class="box-label">
                  <span class="label-product label-sale"
                    >- {{ item.discount }} %</span
                  >
                </div>
                <div class="product-image-container second_img">
                  <a
                    @click="viewProduct(item.item_id)"
                    target="_self"
                    :title="item.title"
                    v-if="item.imgpath.length > 1"
                  >
                    <img
                      v-for="(imagepath, index) in item.imgpath.slice(0, 2)"
                      :key="index"
                      :src="imagepath"
                      :class="'img-responsive img-' + parseInt(index + 1)"
                      style="height: 180px; width: 180px; object-fit: cover"
                    />
                  </a>
                  <a
                    @click="viewProduct(item.item_id)"
                    target="_self"
                    :title="item.title"
                    v-if="item.imgpath.length === 1"
                  >
                    <img
                      v-for="index in 2"
                      :key="index"
                      :src="item.imgpath[0]"
                      :class="'img-responsive img-' + parseInt(index + 1)"
                      style="height: 180px; width: 180px; object-fit: cover"
                    />
                  </a>
                </div>
              </div>
              <div class="right-block">
                <div class="button-group so-quickview cartinfo--left">
                  <button
                    type="button"
                    class="addToCart"
                    title="Add to cart"
                    @click="quickViewProductModal(item.item_id)"
                  >
                    <span>Add to cart </span>
                  </button>
                  <button
                    v-if="item.wishlit === false"
                    type="button"
                    class="wishlist btn-button"
                    title="Add to Wish List"
                    @click="addToWishList(item, item.item_id)"
                  >
                    <i class="fa fa-heart-o"></i><span>Add to Wish List</span>
                  </button>
                  <button
                    v-if="item.wishlit === true"
                    type="button"
                    class="wishlist btn-button"
                    style="color: #fff; background-color: #005baa"
                    title="Remove to Wish List"
                    @click="removeFromWishList(item, item.item_id)"
                  >
                    <i class="fa fa-heart-o"></i>
                  </button>
                </div>

                <div class="caption hide-cont">
                  <div class="ratings">
                    <div class="rating-box">
                      <span class="fa fa-stack"
                        ><i
                          :class="[
                            item.rating >= 1 ? ' fa fa-star' : 'fa fa-star-o',
                          ]"
                          class="fa-stack-2x"
                        ></i
                      ></span>
                      <span class="fa fa-stack"
                        ><i
                          :class="[
                            item.rating >= 2 ? ' fa fa-star' : 'fa fa-star-o',
                          ]"
                          class="fa-stack-2x"
                        ></i
                      ></span>
                      <span class="fa fa-stack"
                        ><i
                          :class="[
                            item.rating >= 3 ? ' fa fa-star' : 'fa fa-star-o',
                          ]"
                          class="fa-stack-2x"
                        ></i
                      ></span>
                      <span class="fa fa-stack"
                        ><i
                          :class="[
                            item.rating >= 4 ? ' fa fa-star' : 'fa fa-star-o',
                          ]"
                          class="fa-stack-2x"
                        ></i
                      ></span>
                      <span class="fa fa-stack"
                        ><i
                          :class="[
                            item.rating >= 5 ? ' fa fa-star' : 'fa fa-star-o',
                          ]"
                          class="fa-stack-2x"
                        ></i
                      ></span>
                    </div>
                    <!-- <span v-if="item.ratings != undefined" class="rating-num">( {{item.rating}} )</span>                      -->
                  </div>

                  <h4
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <a
                      @click.prevent.stop="pushToProduct()"
                      title="Pastrami bacon"
                      target="_self"
                      >{{ item.product_name }}</a
                    >
                  </h4>
                </div>

                <p class="price">
                  <span class="price-new">Rs.{{ item.price }}</span
                  >&nbsp;&nbsp;
                  <span class="price-old" v-if="item.prediscount != ''"
                    >Rs.{{ item.prediscount }}</span
                  >
                </p>

                <div class="item-available">
                  <div
                    style="
                      background-color: #eee;
                      height: 13px;
                      width: 100%;
                      display: inline-block;
                      border-radius: 7px;
                      position: relative;
                      z-index: 2;
                      overflow: hidden;
                    "
                  >
                    <span
                      class="color_width"
                      data-title="77%"
                      data-toggle="tooltip"
                      :style="'width:' + item.SoldPresentage + ';'"
                      style="
                        background: #005baa;
                        position: absolute;
                        height: 100%;
                        left: 0;
                        border-top-left-radius: 7px;
                        border-bottom-left-radius: 7px;
                      "
                    ></span>
                  </div>
                  <p class="price" style="text-align: center">
                    Sold: <b>{{ item.sold_amount }}</b>
                  </p>
                </div>

                <!-- <div class="description item-desc">
                      <p>{{item.title}}</p>
                  </div> -->

                <!-- <div class="list-block">
                      <button class="addToCart btn-button" type="button" title="Add to Cart" @click="quickViewProductModal(item.item_id)"><i class="fa fa-shopping-basket"></i>
                      </button>
                      <button class="wishlist btn-button" type="button" title="Add to Wish List"  @click="addToWishList(item.item_id)" ><i class="fa fa-heart"></i>
                      </button>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--// End Changed listings-->

        <!-- Filters -->
        <!-- <div class="product-filter product-filter-bottom filters-panel">
          <div class="row">
              <div class="col-sm-6 text-left"></div>
              <div class="col-sm-6 text-right">Showing 1 to 15 of 15 (1 Pages)</div>
          </div>
        </div> -->
        <!-- //end Filters -->
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="productModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
            <h5 class="modal-title" id="productModalLongTitle">
              Product Quick View
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top: -20px"
            >
              <span  style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ProductModalContent v-bind:pid="this.product_id" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              data-dismiss="modal"
              style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinProduct from "@/mixins/buyer/APIProduct";
import mixinPromotions from "@/mixins/buyer/APIPromotions";
import ProductModalContent from "@/components/product/product_modal.vue";
export default {
  components: {
    ProductModalContent,
  },
  mixins: [mixinProduct, mixinPromotions],
  data() {
    return {
      blockLoading: true,
      breadcrumb: [
        {
          text: "Flash Deal",
          href: "/flashDeal",
          active: false,
        },
      ],
      showmode: "list",
      blocks: [],

      product_id: "",
      inputNumValue: 1,
      isDisable: true,
      idObj: {
        ids: [],
      },
      dealHours: 0,
      dealMinutes: "0.0",
      dealSeconds: 0,
      nextdealHours: 0,
      nextdealMinutes: "0.0",
      nextdealSeconds: 0,
      showNextTimer: false,
      showNowTimer: false,
      image: "",
      SoldPresentage: {
        width: "",
      },
    };
  },
  computed: {
    flashbannerimage: function () {
      if (this.image) {
        return this.image;
      } else {
        this.blockdata = false;
        return "assets/image/catalog/demo/category/img-cate.jpg";
      }
    },
  },
  mounted: function () {
    this.handleBreadcrumb();

    this.display("grid");
  },
  created() {
    this.initiateList();
    this.getFlash();
  },
  methods: {
    quickViewProductModal: function (pid) {
      this.product_id = pid;

      this.inputNumValue = 1;
      this.isDisable = true;
      this.idObj = {
        ids: [],
      };

      $("#productModalCenter").modal("show");
    },
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    initiateList() {
      let self = this;
      $(".list-view .btn").each(function () {
        $(this).siblings(".btn").removeClass("active");
      });
    },
    display(view) {
      $(".products-list").removeClass("list grid").addClass(view);
      $(".list-view .btn").removeClass("active");
      if (view == "list") {
        //$('.products-list .product-layout').addClass('col-lg-12');
        // $('.products-list .product-layout .left-block').addClass('col-md-4');
        // $('.products-list .product-layout .right-block').addClass('col-md-8');
        $(".products-list .product-layout .item-desc").removeClass("hidden");
        $(".products-list .product-layout .list-block").removeClass("hidden");
        $(".products-list .product-layout .button-group").addClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "list");
      } else {
        //$('.products-list .product-layout').removeClass('col-lg-12');
        //$('.products-list .product-layout .left-block').removeClass('col-md-4');
        //$('.products-list .product-layout .right-block').removeClass('col-md-8');
        $(".products-list .product-layout .item-desc").addClass("hidden");
        $(".products-list .product-layout .list-block").addClass("hidden");
        $(".products-list .product-layout .button-group").removeClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "grid");
      }

      this.showmode = view;
    },
    nextdealTime(startdate, dateend) {
      var startDate = new Date(startdate);
      // Do your operations
      var endDate = new Date(dateend);
      var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      clearInterval(nexttimer);
      if (seconds <= 0) {
        this.nextdealHours = 0;
        this.nextdealMinutes = "0.0";
        this.nextdealSeconds = 0;
        return;
      }
      let self = this;
      var nexttimer = setInterval(function () {
        seconds = seconds - 1;
        if (self.$route.name != "Flash Deal") {
          clearInterval(nexttimer);
        }

        if (seconds <= 0) {
          clearInterval(nexttimer);
          self.showNextTimer = false;
          self.getFlash();
          return;
        }
        self.showNextTimer = true;

        // console.log(seconds);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(seconds / 3600);
        var textseconds = seconds - minutes * 60;
        var textminutes = String((seconds % 3600) / 60);
        self.nextdealHours = hours;
        self.nextdealMinutes = textminutes;
        self.nextdealSeconds = textseconds;
        // console.log(hours + ' : ' + textminutes + ' : ' + textseconds);
      }, 1000);
    },
    dealTime(startdate, dateend) {
      var startDate = new Date(startdate);
      // Do your operations
      var endDate = new Date(dateend);
      var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      clearInterval(timer);
      if (seconds <= 0) {
        this.dealHours = 0;
        this.dealMinutes = "0.0";
        this.dealSeconds = 0;
        return;
      }
      let self = this;
      var timer = setInterval(function () {
        seconds = seconds - 1;
        if (self.$route.name != "Flash Deal") {
          clearInterval(timer);
        }
        if (seconds <= 0) {
          clearInterval(timer);
          self.showNowTimer = false;
          self.getFlash();
          return;
        }
        self.showNowTimer = true;
        // console.log(seconds);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(seconds / 3600);
        var textseconds = seconds - minutes * 60;
        var textminutes = String((seconds % 3600) / 60);
        self.dealHours = hours;
        self.dealMinutes = textminutes;
        self.dealSeconds = textseconds;
        // console.log(hours + ' : ' + textminutes + ' : ' + textseconds);
      }, 1000);
    },
    getFlash: async function () {
      try {
        this.blockLoading = true;
        let blockdata = [];
        this.blocks = [];
        let data = await this.getFlashDeals();
        let qty = data.now.qty;
        let productlist = [];
        if (data.now.products) {
          productlist = data.now.products;
          this.dealTime(data.time_now, data.now.end_date_time);
          if (data.now.image != "") {
            this.image = data.now.image;
          }
        } else {
          if (data.next.end_date_time) {
            this.nextdealTime(data.time_now, data.next.start_date_time);
            if (data.next.image != "") {
              this.image = data.next.image;
            }
          }

          return;
        }

        if (productlist.length === 0) {
          return;
        }
        for (let index = 0; index < productlist.length; index++) {
          // productlist[index].simple_product.sold_amount = 50;
          let SoldPresentage =
            (productlist[index].simple_product.sold_amount / qty) * 100 + "%";

          //CHANGE THIS WITH SHYAMIN
          let imgs = [];
          let imagearray = productlist[index].simple_product.images;
          if (productlist[index].simple_product !== null) {
            for (let index2 = 0; index2 < imagearray.length; index2++) {
              let imgpath = imagearray[index2].image;
              imgs.push(imgpath);
            }
          }

          // if (productlist[index].rate.toString().includes(".")) {
          //     let value1 = productlist[index].rate.toString().split(".");
          //     productlist[index].rateAvg = value1[0];
          //     if (value1[1] === "5" || value1[1] > "5") {
          //         productlist[index].rateAvg = parseInt(productlist[index].rateAvg) + 1;
          //     }
          // } else {
          //     productlist[index].rateAvg = productlist[index].rate;
          // }
          let oldprice = "";
          let newprice = "";
          let discount = "";
          if (
            productlist[index].simple_product.special_price != 0 &&
            productlist[index].simple_product.special_price != ""
          ) {
            newprice = productlist[index].simple_product.special_price;
            oldprice = productlist[index].simple_product.price;
            let balance = oldprice - newprice;
            discount = (balance / oldprice) * 100;
            discount = discount.toFixed(0);
          } else {
            newprice = productlist[index].simple_product.price;
            oldprice = "";
          }

          blockdata.push({
            blockdatacomponent: "ProductTile",
            discountprecentage: "",
            imgpath: imgs,
            item_id: productlist[index].simple_product.product_id,
            prediscount: oldprice,
            discount: discount,
            price: newprice,
            sold_amount: productlist[index].simple_product.sold_amount,
            SoldPresentage: SoldPresentage,
            product_name: productlist[index].simple_product.product_name,
            primary_product: productlist[index].primary_product,
            rating: productlist[index].rate,
            title: productlist[index].product_name,
            wishlit: productlist[index].wishlit,
            url: "/product/" + productlist[index].simple_product.product_id,
          });
        }
        this.blocks = blockdata;
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    viewProduct: function (id) {
      this.$router.push({ path: "product", query: { pid: id } });
    },
    pushToProduct() {
      this.$router.push("/product");
    },
    addToWishList: async function (data, item_id) {
      if (this.$store.state.buyer_accessToken === null) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Please login to add to Wishlist!",
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      } else {
        try {
          let wishlistItem = { product_id: item_id, status: 1 };
          let response = await this.saveWishliat(wishlistItem);
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Added to Wishlist",
            showConfirmButton: false,
            timer: 1500,
          });
          data.wishlit = true;
        } catch (error) {
          throw error;
        }
      }
    },
    removeFromWishList: async function (data, item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 2 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Removed from Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        data.wishlit = false;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<style>
#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.m0p0 {
  margin: 0px !important;
  padding: 0px !important;
}

.productTileContainerFlash {
  width: 20% !important;
}

@media screen and (max-width: 1400px) {
  .productTileContainerFlash {
    width: 33.333333% !important;
  }
}

@media screen and (max-width: 992px) {
  .productTileContainerFlash {
    width: 50% !important;
  }
}

@media screen and (max-width: 500px) {
  .productTileContainerFlash {
    width: 100% !important;
  }
}

@media screen and (max-width: 550px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 14px;
  }
}
@media screen and (max-width: 475px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 12px;
  }

}
@media screen and (max-width: 410px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 9px;
  }

}
@media screen and (max-width: 355px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    font-size: 7px;
  }
}
@media screen and (max-width: 320px) {
  .deals-layout1 .cslider-item-timer .product_time_maxprice .time-item .num-time{
    margin-bottom: 15px;
  }
}
</style>